import axios from "axios";

export const createCheckoutSessionFn = async (id, priceId, customerEmail) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_HOST}/stripe/create-checkout-session`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          priceId,
          customerEmail,
          courseId: id,
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    const session = await response.json();
    return session;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};
