import React from "react";

import Cookies from "universal-cookie";
import { resetCookies } from "../../utils/ResetCookies";
import { Link } from "react-router-dom";
import { resetQueries } from "../../utils/ResetQueries";
import { useTranslation } from "react-i18next";
import BBLearningLogo from "../../images/svg/bechbox_learning_logo.svg";
const cookies = new Cookies();

const Header = () => {
  const token = cookies.get("TOKEN");
  const admin = cookies.get("TOKEN") && cookies.get("ADMIN");
  const { t } = useTranslation();
  const logout = () => {
    resetCookies(cookies);
    resetQueries();
    // redirect user to the landing page
    window.location.href = "/";
  };

  return (
    <div className="header">
      <Link to="/" className="header-logo">
        <img src={BBLearningLogo} alt="BechBox Learning logo" />
      </Link>
      <div className="header-nav">
        <Link to="/support">{t("support")}</Link>
        {admin === "true" && (
          <>
            <Link to="/promocodes">Codes</Link>
            <Link to="/promocode-generate">Generate</Link>
            <Link to="/users">Users</Link>
          </>
        )}
        <Link to="/courses">{t("courses")}</Link>
        {token ? (
          <>
            <Link to="/dashboard">{t("your-courses")}</Link>
            <button
              className="button-standard"
              type="submit"
              variant="danger"
              onClick={() => logout()}
            >
              {t("logout")}
            </button>
          </>
        ) : (
          <Link to="/login">
            <button className="button-standard">{t("login")}</button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Header;
