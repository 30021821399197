import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import graphQLClient from "../api/graphqlClient";
import { LESSON_QUERY } from "../queries/lessonQuery";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { renderOptions } from "../utils/richText";
import { fetchLessonStatusFn, markLessonAsDoneFn } from "../api/courses";
import { useTranslation } from "react-i18next";

import Cookies from "universal-cookie";
import Section from "./Section";
import Task from "./Task";
const cookies = new Cookies();

function Lesson({ id, courseId }) {
  const { t } = useTranslation();
  const userId = cookies.get("ID");
  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery(["lesson", id], async () => {
    const data = await graphQLClient.request(LESSON_QUERY, { id });
    return data;
  });

  const { data: lessonStatusData, error: lessonStatusError } = useQuery(
    ["lessonStatus", id, courseId, userId],
    () => fetchLessonStatusFn({ lessonId: id, courseId, userId }),
    { enabled: !!userId }
  );

  const mutation = useMutation((lessonData) => markLessonAsDoneFn(lessonData), {
    onSuccess: () => {
      queryClient.invalidateQueries(["courseData", courseId]);
      // TODO: when I reach this line I need the UI to reflect that the lessonStatusData is completede - how?
      queryClient.setQueryData(["lessonStatus", id, courseId, userId], {
        ...lessonStatusData,
        isCompleted: true,
      });
    },
    onError: () => {
      // Notify user of error
      alert("Failed to mark lesson as done");
    },
  });

  const handleMarkAsDone = () => {
    mutation.mutate({ lessonId: id, courseId, userId });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  if (lessonStatusError) {
    return (
      <div>Error fetching completion status: {lessonStatusError.message}</div>
    );
  }

  return (
    <div className="lesson">
      <h1>{data.lesson.title}</h1>
      {data.lesson.body &&
        documentToReactComponents(
          data.lesson.body.json,
          renderOptions(data.lesson.body.links)
        )}
      {data.lesson.sectionsCollection.items.map((item, index) => {
        if (item.__typename === "Section") {
          return <Section key={index} id={item.sys.id} />;
        }

        if (item.__typename === "Task") {
          return <Task key={index} id={item.sys.id} />;
        }

        return null;
      })}
      {lessonStatusData?.isCompleted ? (
        <div className="lesson-status lesson-status-complete">
          <div>{t("lesson-complete")}</div>
        </div>
      ) : (
        <div className="lesson-status">
          <button className="lesson-done-button" onClick={handleMarkAsDone}>
            {t("mark-as-done")}
          </button>
        </div>
      )}
    </div>
  );
}

export default Lesson;
