import React from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchUserDataFn, toggleEnrollmentFn } from "../api/users";
import { fetchCoursesFn } from "../api/courses";
import Header from "./ui/Header";
import { useQueryClient } from "@tanstack/react-query";

function User() {
  const { id } = useParams();
  const queryClient = useQueryClient();

  // Fetch user data
  const {
    isLoading,
    isError,
    error,
    data: user = [],
  } = useQuery({
    queryKey: ["user", id],
    staleTime: Infinity,
    queryFn: () => fetchUserDataFn(id),
  });

  // Fetch all courses
  const { data: courses = [], isLoading: coursesLoading } = useQuery({
    queryKey: ["userCourses", id],
    staleTime: Infinity,
    queryFn: () => fetchCoursesFn(),
  });

  // Toggle enrollment mutation
  const mutation = useMutation(toggleEnrollmentFn, {
    onSuccess: () => {
      // This will refetch the user's data after a successful mutation
      queryClient.invalidateQueries(["user", id]);
    },
  });

  const handleToggleEnrollment = (courseId) => {
    mutation.mutate({ userId: id, courseId });
  };

  if (isLoading || coursesLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="wrapper">
      <Header />
      <div className="wrapper-inner">
        <div>
          <h2>User Details</h2>
          <p>
            <strong>Name:</strong> {user.name} {user.surname}
          </p>
          <p>
            <strong>Email:</strong> {user.email}
          </p>
          <p>
            <strong>Confirmed:</strong>{" "}
            {user.confirmed === true ? "true" : "false"}
          </p>
          <p>
            <strong>Enrollments:</strong> {user.enrollments.length}
          </p>

          <h3>Courses</h3>
          <div>
            {courses.map((course) => (
              <div key={course.id} className="enrollments">
                <span>{course.name}</span>
                <button
                  className={`toggle-button button-standard ${
                    user.enrollments.some(
                      (enrollment) => enrollment.courseId === course.id
                    )
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleToggleEnrollment(course.id)}
                >
                  {user.enrollments.some(
                    (enrollment) => enrollment.courseId === course.id
                  )
                    ? "Unenroll"
                    : "Enroll"}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default User;
