import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { loginUserFn } from "../../api/users";
import InlineToast from "../ui/InlineToast";
import { useTranslation } from "react-i18next";

import Cookies from "universal-cookie";
import { Link, Navigate, useNavigate } from "react-router-dom";
import LoadingButton from "../ui/LoadingButton";
const cookies = new Cookies();

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const mutation = useMutation(loginUserFn, {
    onSuccess: (data) => {
      setErrorMessage(null);
      cookies.set("TOKEN", data.token, {
        path: "/",
        expires: new Date(new Date().getTime() + 60 * 60 * 24 * 1000),
      });
      cookies.set("USERNAME", data.user.email, { path: "/" });
      cookies.set("ID", data.user.id, { path: "/" });
      if (data.user.role === "admin") {
        cookies.set("ADMIN", true, { path: "/" });
      } else {
        cookies.set("ADMIN", false, { path: "/" });
      }

      if (!data.user.confirmed) {
        navigate("/resendconfirm");
      } else {
        // redirect user to the dashboard
        navigate("/dashboard");
      }
    },
    onError: (error) => {
      setIsSendingCode(false);
      setErrorMessage(error.response?.data?.message || "Login failed.");
    },
  });

  const onSubmit = (data) => {
    setIsSendingCode(true);

    mutation.mutate(data);
  };

  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const token = cookies.get("TOKEN");
  if (token) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div>
      <div className="login">
        <div className="login-info"></div>
        <div className="login-form-col">
          <div className="login-form-content">
            <h2>{t("greeting")}!</h2>
            <p>
              {t("login-message01")} -{" "}
              <Link to="/register">{t("sign-up-here")}</Link>{" "}
              {t("login-message02")}
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <fieldset>
                  <label htmlFor="email">Email:</label>
                  <input
                    autoFocus
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email email-address"
                    {...register("email", {
                      required: true,
                      pattern: emailPattern,
                    })}
                  />
                  {errors.email && (
                    <InlineToast
                      message={`Email is required.`}
                      type="error"
                      size="small"
                    />
                  )}
                  {errors.email && errors.email.type === "pattern" && (
                    <p className="form-error">
                      Please enter a valid email address.
                    </p>
                  )}
                </fieldset>
              </div>
              <div>
                <fieldset>
                  <label htmlFor="password">{t("password")}:</label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="password"
                    {...register("password", { required: true })}
                  />
                  {errors.password && (
                    <InlineToast
                      message={`Password is required.`}
                      type="error"
                      size="small"
                    />
                  )}
                </fieldset>
              </div>
              <LoadingButton
                isLoading={isSendingCode}
                type="submit"
                onClick={(e) => handleSubmit(e)}
                className="login-button"
              >
                {t("login")}
              </LoadingButton>
            </form>
            {errorMessage && (
              <InlineToast
                message={`${errorMessage} If you don't have an account you need to sign up first`}
                type="error"
                size="medium"
              />
            )}
            <div className="after-form spaced">
              <Link to="/forgot-password">{t("forgot-password")}</Link>
              <Link to="/register">{t("sign-up-here")}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
