import React from "react";
const Vimeo = ({
  videoSrcURL,
  videoTitle,
  iframeStyle = "video-iframe-default",
  ...props
}) => (
  <>
    <div className="video-container">
      <iframe
        src={videoSrcURL}
        title={videoTitle}
        className={iframeStyle}
        allow="autoplay; fullscreen; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
    <script src="https://player.vimeo.com/api/player.js"></script>
  </>
);
export default Vimeo;
