import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchCoursesFn } from "../api/courses";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import Header from "./ui/Header";
import { useTranslation } from "react-i18next";
import Footer from "./ui/Footer";
import Spinner from "./ui/Spinner";

const cookies = new Cookies();
const admin = cookies.get("TOKEN") && cookies.get("ADMIN");

const Courses = () => {
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    error,
    data: courses = [],
  } = useQuery({
    queryKey: ["courses"],
    queryFn: () => fetchCoursesFn(),
    staleTime: Infinity,
    onSuccess: (data) => {
      console.log("Data fetched successfully:", data);
    },
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });

  if (isLoading) {
    return (
      <div className="load-screen">
        <div className="load-content">
          <Spinner />
          <div className="load-msg">Loading courses...</div>
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching courses: {error.message}</div>;
  }

  //console.log(courses);

  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="wrapper-inner">
          <h1>{t("courses-available")}</h1>
          {courses &&
            courses.map((course, index) => (
              <div key={course.id}>
                <Link to={course.url}>
                  <div className="course-card">
                    <div className="course-card-image">
                      <img src={course.thumbnailUrl} alt="course thumbnail" />
                    </div>
                    <h3>{course.name}</h3>
                    {admin && (
                      <Link to={`/course/edit/${course.id}`}>Edit</Link>
                    )}
                  </div>
                </Link>
              </div>
            ))}
          {admin && (
            <Link to="/course/new">
              <button className="button-standard">New</button>
            </Link>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Courses;
