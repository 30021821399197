import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import Header from "../components/ui/Header";
import { Link, useParams } from "react-router-dom";

import Cookies from "universal-cookie";
import { redeemPromoCodeFn } from "../api/promocodes";
import InlineToast from "../components/ui/InlineToast";
const cookies = new Cookies();

const PromoCode = () => {
  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(false);
  // Initialize the useForm hook
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { id } = useParams();
  const userId = cookies.get("ID");

  const mutation = useMutation((courseData) => redeemPromoCodeFn(courseData));

  const onSubmit = (data) => {
    mutation.mutate(
      { userId: userId, courseId: id, promocode: data.promoCode },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["userCourses"]);
          queryClient.invalidateQueries(["allCourses"]);
          setSuccessMessage(true);
        },
        onError: (error) => {
          setErrorMessage(error.response?.data?.error || "Promo code failed.");
        },
      }
    );
  };

  return (
    <>
      <div className="wrapper">
        <div className="index">
          <Header />
          <div className="wrapper-inner cols">
            <div>
              <h1>Brug Kode</h1>
              <p className="text-md">
                Hvis du har fået en kode til at tilmelde dig kurset (f.eks. fra
                DJ:Fotografer), så skriv den her:
              </p>
              {successMessage ? (
                <>
                  <h3>Kode indløst</h3>
                  <Link to="/dashboard">Gå til din oversigt her</Link>
                </>
              ) : (
                <>
                  {/* Add a form to submit the promo code */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                      type="text"
                      {...register("promoCode", { required: true })}
                      placeholder="Indtast promo kode"
                      className="promo-code-input"
                    />
                    {errors.promoCode && (
                      <p className="error">Promo kode er påkrævet.</p>
                    )}
                    <br />
                    <br />
                    <button type="submit" className="button-standard">
                      Indløs
                    </button>
                  </form>
                  {errorMessage && (
                    <InlineToast
                      message={errorMessage}
                      type="error"
                      size="medium"
                    />
                  )}
                </>
              )}

              <br />
              <br />

              <p>
                Hvis der er problemer af nogen art, så tøv ikke med at skrive en
                mail til{" "}
                <a href="mailto:mikkel@bechbox.dk">mikkel@bechbox.dk</a>
              </p>
            </div>
            <div> </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoCode;
