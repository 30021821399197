import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import Modal from "./ui/Modal";
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();

const SessionHandler = ({ children }) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const navigate = useNavigate();

  const checkTokenExpiry = () => {
    if (cookies.get("TOKEN") === undefined) {
      setIsSessionExpired(true);
    }
  };

  useEffect(() => {
    const clickHandler = () => {
      checkTokenExpiry();
    };

    window.addEventListener("click", clickHandler);

    return () => {
      window.removeEventListener("click", clickHandler);
    };
  }, []);

  const handleModalClose = () => {
    setIsSessionExpired(false);
  };

  return (
    <>
      {children}
      {isSessionExpired && (
        <Modal onClose={handleModalClose} closeButton="false" size="sm">
          <p>Your session has expired. Please log in again.</p>
          <button
            onClick={() => {
              navigate("/login");
            }}
          >
            Login
          </button>
        </Modal>
      )}
    </>
  );
};

export default SessionHandler;
