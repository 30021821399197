export const LESSON_QUERY = `
  query LessonQuery($id: String!) {
    lesson(id: $id) {
      sys {
        id
      }
      title
      body {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              url
              title
              width
              height
              description
            }
          }
        }
      }
      sectionsCollection {
        items {
          __typename
          ... on Section {
            sys {
              id
            }
            title
            body {
              json
            }
          }
          ... on Task {
            sys {
              id
            }
            title
            body {
              json
            }
          }
        }
      }
    }
  }
`;
