import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { createCourseFn } from "../../api/courses";
import Cookies from "universal-cookie";
import InlineToast from "../ui/InlineToast";
import { Link } from "react-router-dom";
const cookies = new Cookies();

const CourseCreate = () => {
  const { register, handleSubmit, reset, formState } = useForm();
  const { errors } = formState; // Destructure errors from formState

  const mutation = useMutation((courseData) => createCourseFn(courseData));

  const onSubmit = (data) => {
    mutation.mutate(data, {
      onSuccess: () => {
        reset();
      },
    });
  };

  return (
    <>
      <h2>Add a new course</h2>
      <form className="form-default" onSubmit={handleSubmit(onSubmit)}>
        <fieldset>
          <label htmlFor="name">Name</label>
          <input
            {...register("name", { required: true })}
            id="name"
            type="text"
            placeholder="Name of Course"
          />
          {errors.name && (
            <InlineToast
              message={`Name is required`}
              type="error"
              size="small"
            />
          )}
        </fieldset>
        <fieldset>
          <label htmlFor="contentful_Id">contentful_Id</label>
          <input
            {...register("contentful_Id", { required: true })}
            id="contentful_Id"
            type="text"
            placeholder="Contentful ID"
          />
          {errors.contentful_Id && (
            <InlineToast
              message={`contentful_Id is required`}
              type="error"
              size="small"
            />
          )}
        </fieldset>
        <fieldset>
          <label htmlFor="price">Price</label>
          <input
            {...register("price", { required: true })}
            id="price"
            type="text"
            placeholder="Price of Course"
          />
          {errors.name && (
            <InlineToast
              message={`Price is required`}
              type="error"
              size="small"
            />
          )}
        </fieldset>
        <fieldset>
          <label htmlFor="url">URL</label>
          <input
            {...register("url", { required: true })}
            id="url"
            type="text"
            placeholder="http://"
          />
          {errors.name && (
            <InlineToast
              message={`Url is required`}
              type="error"
              size="small"
            />
          )}
        </fieldset>
        <button
          type="submit"
          disabled={formState.isSubmitting}
          className="button-standard"
        >
          Add Course
        </button>
      </form>
      {mutation.isError && (
        <p className="error-message">
          Error creating course: {mutation.error.message}
        </p>
      )}
      {mutation.isSuccess && (
        <p className="success-message">
          Course added:{" "}
          <Link to={`/course/${mutation.data.contentful_Id}`}>
            {mutation.data.name}
          </Link>
        </p>
      )}
    </>
  );
};

export default CourseCreate;
