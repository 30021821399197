import { Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./components/Auth/ProtectedRoutes";

// Supports weights 100-900
import "@fontsource-variable/inter";
import "@fontsource-variable/bricolage-grotesque";
import "./App.scss";

import NotFound from "./pages/NotFound";
import GenerativeAI from "./courses/GenerativeAI";
import Course from "./components/Course";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import ResendConfirm from "./components/Auth/ResendConfirm";
import ConfirmEmail from "./components/Auth/ConfirmEmail";
import Dashboard from "./components/Dashboard";
import ProtectedAdminRoutes from "./components/Auth/ProtectedAdminRoutes";
import CourseCreate from "./components/admin/CourseCreate";
import Courses from "./components/Courses";
import CourseEdit from "./components/admin/CourseEdit";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentError from "./components/PaymentError";
import Users from "./components/Users";
import User from "./components/User";
import SupportPage from "./pages/SupportPage";
import PromoCode from "./pages/PromoCode";
import PromoCodeGenerator from "./components/admin/PromoCodeGenerator";
import PromocodesList from "./components/admin/PromoCodeList";
import GenerativeLanding01 from "./landing-pages/generativeLanding01";
import ForgotPassword from "./components/Auth/ForgotPassword";
import Prompting from "./articles/prompting";
import Password from "./components/Auth/Password";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<GenerativeAI />} />
      <Route
        exact
        path="/generative-masterclass"
        element={<GenerativeLanding01 />}
      />
      <Route exact path="/articles/prompting" element={<Prompting />} />
      <Route exact path="/support" element={<SupportPage />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/resendconfirm" element={<ResendConfirm />} />
      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/resetpassword/:id/:token" element={<Password />} />
      <Route exact path="/confirm/:id" element={<ConfirmEmail />} />
      <Route
        exact
        path="/dashboard"
        element={
          <ProtectedRoutes>
            <Dashboard />
          </ProtectedRoutes>
        }
      />
      <Route exact path="/courses" element={<Courses />} />
      <Route exact path="/generativ-ai" element={<GenerativeAI />} />
      <Route
        exact
        path="/promocode/:id"
        element={
          <ProtectedRoutes>
            <PromoCode />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/promocodes"
        element={
          <ProtectedRoutes>
            <PromocodesList />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/promocode-generate"
        element={
          <ProtectedAdminRoutes>
            <PromoCodeGenerator />
          </ProtectedAdminRoutes>
        }
      />
      <Route
        exact
        path="/course/:id"
        element={
          <ProtectedRoutes>
            <Course />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/course/new"
        element={
          <ProtectedAdminRoutes>
            <CourseCreate />
          </ProtectedAdminRoutes>
        }
      />
      <Route
        exact
        path="/course/edit/:id"
        element={
          <ProtectedAdminRoutes>
            <CourseEdit />
          </ProtectedAdminRoutes>
        }
      />
      <Route
        exact
        path="/users"
        element={
          <ProtectedAdminRoutes>
            <Users />
          </ProtectedAdminRoutes>
        }
      />
      <Route
        exact
        path="/user/:id"
        element={
          <ProtectedAdminRoutes>
            <User />
          </ProtectedAdminRoutes>
        }
      />
      <Route
        exact
        path="/success"
        element={
          <ProtectedRoutes>
            <PaymentSuccess />
          </ProtectedRoutes>
        }
      />
      <Route
        exact
        path="/cancel"
        element={
          <ProtectedRoutes>
            <PaymentError />
          </ProtectedRoutes>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
