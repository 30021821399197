import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import graphQLClient from "../api/graphqlClient";
import { COURSE_DETAIL_QUERY } from "../queries/courseDetailQuery";
import { fetchCourseSalesDataFn } from "../api/courses";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { renderOptions } from "../utils/richText";
import Header from "../components/ui/Header";
import InlineToast from "../components/ui/InlineToast";
import { getStripe } from "../utils/getStripe";
import { useTranslation } from "react-i18next";
import Spinner from "../components/ui/Spinner";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { createCheckoutSessionFn } from "../api/stripe";
import Footer from "../components/ui/Footer";
const cookies = new Cookies();

function GenerativeAI() {
  const { t } = useTranslation();
  // TODO: hent fra DB:
  const contentful_Id = "4txQBrx0oojlpfkiBvNOAS";
  // TODO: hent fra DB - lige nu er det manuelt kopieret fra Stripe:
  const priceId = "price_1NoOxwL0mIVb9wax4kcNTuV5";
  const id = 1;
  const userId = cookies.get("ID");
  const token = cookies.get("TOKEN");

  const STYLES = {
    videoIframeContainer: { padding: "56.25% 0 0 0", position: "relative" },
    videoIframe: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
    },
  };

  const [stripeError, setStripeError] = useState(null);

  const handleBuy = async () => {
    try {
      const stripe = await getStripe();

      createCheckoutSessionFn(id, priceId, cookies.get("USERNAME"))
        .then((session) => {
          if (session) {
            return stripe.redirectToCheckout({ sessionId: session.id });
          } else {
            // Handle the error (e.g., display an error message)
            console.error("session error");
            setStripeError("session error");
          }
        })
        .then(function (result) {
          if (result?.error) {
            // Display error.message to your customer
            //console.log(result.error.message);
            setStripeError(result.error.message);
          }
        })
        .catch(function (error) {
          console.error("Error:", error);
          setStripeError("Error: " + error);
        });
    } catch (error) {
      console.error("Error:", error);
      setStripeError("Error: " + error);
    }
  };

  const {
    isLoading,
    isError,
    error,
    data: salesInfo,
  } = useQuery({
    queryKey: ["salesInfo"],
    queryFn: () => fetchCourseSalesDataFn(id, userId),
    staleTime: Infinity,
  });

  const {
    data: details,
    error: detailsError,
    isLoading: detailsLoading,
  } = useQuery(["courseDetails", id], async () => {
    const data = await graphQLClient.request(COURSE_DETAIL_QUERY, {
      id: contentful_Id,
    });
    return data;
  });

  if (isLoading) {
    return (
      <div className="load-screen">
        <div className="load-content">
          <Spinner />
          <div className="load-msg">{t("loading-course")}...</div>
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching course: {error.message}</div>;
  }

  if (detailsLoading) {
    return <div>Loading details...</div>;
  }

  if (detailsError) {
    return <div>Error fetching details: {detailsError.message}</div>;
  }

  return (
    <>
      <Helmet>
        <title>Generative AI</title>
        <meta
          name="description"
          content="Lær generativ AI til billedskabelse med Midjourney, Photoshop (Adobe Firefly), Dall-E, Leonardo.ai, Stable Diffusion, Google Bard i denne online masterclass."
        />
        <meta
          name="keywords"
          content="Generativ AI, midjourney, photoshop, generative fill, masterclass"
        />
        <meta name="author" content="Mikkel Bech, BechBox Learning" />
        <meta property="og:title" content="Generativ billedskabelse med AI" />
        <meta
          property="og:description"
          content="Lær generativ AI til billedskabelse med Midjourney, Photoshop (Adobe Firefly), Dall-E, Leonardo.ai, Stable Diffusion, Google Bard i denne online masterclass"
        />
        <meta
          property="og:image"
          content="https://learning.bechbox.com/images/generativ_ai_thumb.png"
        />
        <meta property="og:url" content="https://learning.bechbox.com/" />
        <meta name="twitter:title" content="Generativ billedskabelse med AI" />
        <meta
          name="twitter:description"
          content="Lær generativ AI til billedskabelse med Midjourney, Photoshop (Adobe Firefly), Dall-E, Leonardo.ai, Stable Diffusion, Google Bard i denne online masterclass"
        />
        <meta
          name="twitter:image"
          content="https://learning.bechbox.com/images/generativ_ai_thumb.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="wrapper">
        <div className="coursepage">
          <Header />
          <div className="hero-section">
            <div className="hero-image-section">
              <div className="hero-image-spacer"></div>
              <div className="hero-image-gradient">
                <img
                  src={details.course.thumbnail.url}
                  alt="course thumbnail"
                  className="hero-image"
                />
              </div>
            </div>
            <div className="course-header">
              <h1>{details.course.title}</h1>
              {details.course.description &&
                documentToReactComponents(
                  details.course.description.json,
                  renderOptions
                )}
              {salesInfo.isEnrolled ? (
                <Link to={`/course/${id}`}>
                  <button className="button-standard">
                    {t("go-to-course")}
                  </button>
                </Link>
              ) : (
                <div>
                  <div className="price-info">
                    {t("price")}:
                    {salesInfo.course.price && (
                      <div className="price">{salesInfo.course.price}</div>
                    )}
                    {salesInfo.course.currency && (
                      <div className="currency">
                        {salesInfo.course.currency}
                      </div>
                    )}
                  </div>
                  {token ? (
                    <div className="button-row">
                      <button onClick={handleBuy} className="button-standard">
                        KØB
                      </button>
                      <Link to="/promocode/1">
                        <button className="button-standard">BRUG KODE</button>
                      </Link>
                      <div>
                        {stripeError && (
                          <InlineToast
                            message={stripeError}
                            size="small"
                            type="error"
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      Af sikkerhedshensyn skal du oprette dig som bruger her på
                      BechBox Learning før du kan kan købe/tilmelde kurset:
                      <br />
                      <br />
                      <Link to="/register" className="hero-register">
                        <button className="button-standard">
                          Opret bruger
                        </button>
                      </Link>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="wrapper-inner course-description cols">
            <div className="overview">
              <h2>
                Kurset indeholder en række video-lektioner, som er nemme at
                følge
              </h2>
              <p>
                Til hver lektion hører en række hands-on opgaver, så du med det
                samme kan bruge hvad du har lært. Desuden får du adgang til et
                online-community, hvor du kan få feedback og hjælp:
              </p>
              <h3>Overblik</h3>
              <ul>
                <li>Forstå teknikken bag de generative modeller</li>
                <li>Midjourney, Photoshop, Stable Diffusion osv</li>
                <li>Opret en profil med Midjourney</li>
                <li>Få skovlen under Discord</li>
              </ul>
              <h3>Additiv Prompting</h3>
              <ul>
                <li>Hvad er prompting</li>
                <li>En systemisk tilgang til prompting</li>
                <li>Skab, kontroller og refinér med additiv prompting</li>
              </ul>
              <h3>Avancerede Midjourney teknikker</h3>
              <ul>
                <li>Fintuning</li>
                <li>Image prompting</li>
                <li>Remixing</li>
                <li>Vary Region</li>
                <li>Style Tuning **NYT nov. 2023</li>
              </ul>
              <h3>Midjourney biblioteket</h3>
              <ul>
                <li>Find inspiration</li>
              </ul>
              <h3>Photoshop</h3>
              <ul>
                <li>Generative Fill</li>
                <li>Set extension</li>
                <li>Virtuelle set</li>
              </ul>
            </div>
            <div className="video">
              <div style={STYLES.videoIframeContainer}>
                <iframe
                  src="https://player.vimeo.com/video/882274409?h=461217b6d3&amp;badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  style={STYLES.videoIframe}
                  title="generativ_promo01"
                ></iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default GenerativeAI;
