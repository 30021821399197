import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchUsersFn } from "../api/users";
import { Link } from "react-router-dom";
import Header from "./ui/Header";

function Users() {
  const parseCompletedLessons = (completedLessons) => {
    try {
      return JSON.parse(completedLessons);
    } catch (error) {
      console.error("Error parsing completedLessons:", error);
      return []; // Return empty array in case of parsing error
    }
  };

  const {
    isLoading,
    isError,
    error,
    data: users = [],
  } = useQuery({
    queryKey: ["users"],
    queryFn: () => fetchUsersFn(),
    staleTime: Infinity,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="wrapper">
      <Header />
      <div className="wrapper-inner">
        <h2>All Users</h2>
        <div className="flex-table">
          <div className="flex-table-header">
            <div className="flex-cell">User</div>
            <div className="flex-cell">Email</div>
            <div className="flex-cell">Completed lessons</div>
            <div className="flex-cell">Last Accessed</div>
          </div>
          {users.map((user) => (
            <div key={user.id} className="flex-table-row">
              <div className="flex-cell">
                <Link to={`/user/${user.id}`}>
                  {user.name} {user.surname}
                </Link>
              </div>

              <div className="flex-cell">{user.email}</div>
              {user.courseProgress.length > 0 ? (
                user.courseProgress.map((progress) => (
                  <>
                    <div key={progress.id} className="flex-cell">
                      {parseCompletedLessons(progress.completedLessons).length}
                    </div>
                    <div className="flex-cell">
                      {new Date(progress.lastAccessed).toLocaleDateString()}
                    </div>
                  </>
                ))
              ) : (
                <div className="flex-cell"></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Users;
