import React from "react";
import { useQuery } from "@tanstack/react-query";
import { confirmEmailFn } from "../../api/users";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ConfirmEmail = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    error,
    data: confirm,
  } = useQuery({
    queryKey: ["confirm"],
    queryFn: () => confirmEmailFn(id),
    staleTime: Infinity,
  });

  if (isLoading) {
    return <div>{t("confirming-email")}</div>;
  }

  if (isError) {
    return (
      <div>
        {t("confirm-email-error")} {error.message}
      </div>
    );
  }

  return (
    <>
      <div className="login">
        <div className="login-form-col">
          <div className="login-form-content">
            <h2>{t("email-confirmed")}</h2>
            {t("go-to")} <Link to="/login">{t("login-page")}</Link>{" "}
            {t("to-begin")}
          </div>
        </div>
        <div className="login-info"></div>
      </div>
    </>
  );
};

export default ConfirmEmail;
