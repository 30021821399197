import React from "react";

const GenerativeLanding01 = () => {
  return (
    <div className="landing-body">
      <link
        rel="preload"
        as="image"
        href="/images/landing01/generativ_ai_landing_hero01.jpg"
      ></link>
      <div className="landing-wrapper">
        <div className="landing-hero">
          <div className="landing-hero-top">
            <div className="landing-hero-pre">Online Masterclass</div>
            <h1>Lær at lave flotte brugbare billeder med generativ AI</h1>
          </div>
          <div className="landing-hero-bottom">
            <p>Udforsk de utrolige muligheder med generativ AI</p>
            <a href="https://learning.bechbox.com" className="landing-button">
              Kom i gang nu
            </a>
          </div>
        </div>
        <div className="landing-section">
          <p>
            Med dette online-kursus på dansk kan alle lære at lave flotte og
            brugbare billeder, som kan bruges i markedsføring, præsentationer,
            idé-udvikling, reference-materiale, visuel kommunikation osv.
          </p>
          <p>
            Følg kurset i dit eget tempo og få adgang til at udveklse erfaringer
            i fælles community
          </p>
        </div>
        <div className="landing-section">
          <div className="landing-2cols">
            <div className="landing-2cols-col">
              <img src="/images/landing01/midjourney01.jpg" alt="Midjourney" />
            </div>
            <div className="landing-2cols-col staggered">
              <img src="/images/landing01/midjourney03.jpg" alt="Midjourney" />
            </div>
          </div>
        </div>
        <div className="landing-dj">
          <img
            src="/images/landing01/dj_logo.png"
            alt="Dansk Journalistforbund logo"
          />
          <div className="landing-dj-text">
            Kurset er udviklet i samarbejde med Dansk Journalistforbund
          </div>
        </div>
        <div className="landing-cta">
          <a href="https://learning.bechbox.com" className="landing-button">
            Køb adgang
          </a>
        </div>
        <div className="landing-section">
          <h3>Eksempler</h3>
          <p>Lifestyle</p>
          <div className="landing-2cols">
            <div className="landing-2cols-col">
              <img src="/images/landing01/midjourney02.jpg" alt="Midjourney" />
            </div>
            <div className="landing-2cols-col">
              <img src="/images/landing01/midjourney07.jpg" alt="Midjourney" />
            </div>
          </div>
        </div>
        <div className="landing-section">
          <p>Marketing</p>
          <div className="landing-2cols">
            <div className="landing-2cols-col">
              <img src="/images/landing01/midjourney06.jpg" alt="Midjourney" />
            </div>
            <div className="landing-2cols-col">
              <img src="/images/landing01/midjourney05.jpg" alt="Midjourney" />
            </div>
          </div>
        </div>
        <div className="landing-section">
          <p>Arkitektur</p>
          <div className="landing-2cols">
            <div className="landing-2cols-col">
              <img src="/images/landing01/midjourney04.jpg" alt="Midjourney" />
            </div>
            <div className="landing-2cols-col">
              <img src="/images/landing01/midjourney09.jpg" alt="Midjourney" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenerativeLanding01;
