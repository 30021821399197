import axios from "axios";

import Cookies from "universal-cookie";

const cookies = new Cookies();

export const fetchCoursesFn = async (userId) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "get",
    url: `${process.env.REACT_APP_SERVER_HOST}/courses/all`,
    headers: {
      Authorization: token,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

export const fetchUserCoursesFn = async (userId) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "get",
    url: `${process.env.REACT_APP_SERVER_HOST}/courses/all/${userId}`,
    headers: {
      Authorization: token,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

export const createCourseFn = async (course) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/courses/new`,
    headers: {
      Authorization: token,
    },
    data: {
      name: course.name,
      contentful_Id: course.contentful_Id,
      price: course.price,
      url: course.url,
    },
  };
  const response = await axios(configuration);
  return response.data;
};

export const fetchCourseDataFn = async (courseId, userId) => {
  const token = cookies.get("TOKEN");
  try {
    if (!courseId || !userId) {
      throw new Error("Course ID and User ID must be provided");
    }

    const configuration = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/courses/get/${courseId}`,
      headers: {
        Authorization: token,
      },
      data: {
        userId: userId,
      },
    };

    const response = await axios(configuration);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 403) {
        throw new Error("Sorry, you don't have access to this course");
      }
      throw new Error(
        `Server responded with a status of ${error.response.status}`
      );
    }
    throw error;
  }
};

export const fetchCourseAdminDataFn = async (courseId, userId) => {
  const token = cookies.get("TOKEN");
  try {
    if (!courseId) {
      throw new Error("Course ID must be provided");
    }

    const configuration = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/courses/admin/get/${courseId}`,
      headers: {
        Authorization: token,
      },
    };

    const response = await axios(configuration);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 403) {
        throw new Error("Sorry, you don't have access to this course");
      }
      throw new Error(
        `Server responded with a status of ${error.response.status}`
      );
    }
    throw error;
  }
};

export const fetchCourseSalesDataFn = async (courseId, userId) => {
  const token = cookies.get("TOKEN");
  try {
    const configuration = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/courses/salesdetails/${courseId}`,
      headers: {
        Authorization: token,
      },
      data: {
        userId: userId,
      },
    };

    const response = await axios(configuration);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 403) {
        throw new Error("Sorry, you don't have access to this course");
      }
      throw new Error(
        `Server responded with a status of ${error.response.status}`
      );
    }
    throw error;
  }
};

export const updateCourseFn = async (courseId, courseData) => {
  const token = cookies.get("TOKEN");
  const response = await axios.put(
    `${process.env.REACT_APP_SERVER_HOST}/courses/update/${courseId}`,
    courseData,
    {
      headers: {
        Authorization: token,
      },
    }
  );

  return response.data;
};

export const enrollUserFn = async ({ userId, courseId }) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/enrollments/new`,
    headers: {
      Authorization: token,
    },
    data: {
      userId: userId,
      courseId: courseId,
    },
  };
  const response = await axios(configuration);
  return response.data;
};

export const markLessonAsDoneFn = async ({ lessonId, courseId, userId }) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/progress`,
    headers: {
      Authorization: token,
    },
    data: {
      userId: userId,
      lessonId: lessonId,
      courseId: courseId,
      progress: "IN_PROGRESS",
    },
  };
  const response = await axios(configuration);
  return response.data;
};

export const fetchLessonStatusFn = async ({ lessonId, courseId, userId }) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/progress/lesson`,
    headers: {
      Authorization: token,
    },
    data: {
      userId: userId,
      lessonId: lessonId,
      courseId: courseId,
    },
  };
  const response = await axios(configuration);
  return response.data;
};
