import React from "react";
import { useQuery } from "@tanstack/react-query";
import graphQLClient from "../api/graphqlClient";
import { SECTION_QUERY } from "../queries/sectionQuery";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { renderOptions } from "../utils/richText";

function Section({ id }) {
  const { data, error, isLoading } = useQuery(["section", id], async () => {
    const data = await graphQLClient.request(SECTION_QUERY, { id });
    return data;
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching section: {error.message}</div>;
  }

  return (
    <>
      <h2>{data.section.title}</h2>
      {data.section.body &&
        documentToReactComponents(
          data.section.body.json,
          renderOptions(data.section.body.links)
        )}
    </>
  );
}

export default Section;
