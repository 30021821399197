import { queryClient } from "./queryClient";

export const resetQueries = () => {
  console.log("Existing queries before reset:", queryClient.getQueryCache());

  queryClient.removeQueries(undefined, { exact: false });
  queryClient.invalidateQueries(undefined, {
    refetchActive: true,
    refetchInactive: true,
  });
  queryClient.clear();
};
