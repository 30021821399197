import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchCourseAdminDataFn, updateCourseFn } from "../../api/courses";

const CourseEdit = () => {
  const { id } = useParams();
  const { register, handleSubmit, setValue, formState } = useForm();

  const {
    isLoading,
    isError,
    error,
    data: course,
  } = useQuery({
    queryKey: ["course"],
    queryFn: () => fetchCourseAdminDataFn(id),
    staleTime: Infinity,
  });

  const mutation = useMutation((courseData) => updateCourseFn(id, courseData));

  const onSubmit = (data) => {
    mutation.mutate(data, {
      onSuccess: () => {
        //console.log(data);
        window.location.href = `${data.url}`;
      },
    });
  };

  useEffect(() => {
    if (course) {
      setValue("name", course.name);
      setValue("url", course.url);
      setValue("price", course.price);
      setValue("currency", course.currency);
      setValue("contentful_Id", course.contentful_Id);
      setValue("thumbnailUrl", course.thumbnailUrl);
    }
  }, [course, setValue]);

  if (isLoading) {
    return <div>Loading course...</div>;
  }

  if (isError) {
    return <div>Error fetching course: {error.message}</div>;
  }

  return (
    <>
      <div className="app">
        <main>
          <div className="backend-screen">
            <div className="container-narrow">
              <h2>Edit Course</h2>
              <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                  <label htmlFor="name">Name:</label>
                  <input
                    {...register("name", { required: true })}
                    id="name"
                    type="text"
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="contentful_Id">contentful_Id:</label>
                  <input
                    {...register("contentful_Id")}
                    id="contentful_Id"
                    type="text"
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="thumbnailUrl">Thumbnail URL:</label>
                  <input
                    {...register("thumbnailUrl")}
                    id="thumbnailUrl"
                    type="text"
                  />
                </fieldset>
                <fieldset>
                  <label htmlFor="url">URL:</label>
                  <input {...register("url")} id="url" type="text" />
                </fieldset>
                <fieldset>
                  <label htmlFor="price">Price:</label>
                  <input {...register("price")} id="price" type="text" />
                </fieldset>
                <fieldset>
                  <label htmlFor="currency">Currency:</label>
                  <input {...register("currency")} id="currency" type="text" />
                </fieldset>

                <button
                  className="button-standard"
                  type="submit"
                  disabled={formState.isSubmitting}
                >
                  Update Course
                </button>
              </form>
              {mutation.isError && (
                <p className="error-message">
                  Error updating course: {mutation.error.message}
                </p>
              )}
              {mutation.isSuccess && (
                <p className="success-message">
                  Course updated: {mutation.data.name}
                </p>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default CourseEdit;
