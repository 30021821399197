import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { saveNewPasswordFn } from "../../api/users";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Password = () => {
  const { t } = useTranslation();

  const { id, token } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const mutation = useMutation(saveNewPasswordFn, {
    onSuccess: () => {
      setErrorMessage(null);
      setSuccessMessage("Nyt kodeord gemt - gå til logind!");
    },
    onError: (error) => {
      setSuccessMessage(null);
      setErrorMessage(
        error.response?.data?.message || "Failed to reset password."
      );
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    mutation.mutate({ password: data.password, id: id, resetToken: token });
  };

  return (
    <div>
      <div className="login">
        <div className="login-info"></div>
        <div className="login-form-col">
          <div className="login-form-content">
            <h2>{t("new-password")}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="password">{t("new-password")}:</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  {...register("password", {
                    required: true,
                  })}
                />
                {errors.password && errors.password.type === "required" && (
                  <p className="form-error">Password is required.</p>
                )}
              </div>
              <button type="submit">{t("save-password")}</button>
            </form>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
            <div className="after-form">
              <Link to="/login">{t("go-to-login")}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Password;
