import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { resendVerificationEmailFn } from "../../api/users";
import InlineToast from "../ui/InlineToast";
import { useTranslation } from "react-i18next";

import Cookies from "universal-cookie";
import { resetCookies } from "../../utils/ResetCookies";
const cookies = new Cookies();
//import LoadingButton from "./Components/LoadingButton";

const ResendConfirm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const { t } = useTranslation();

  const mutation = useMutation(resendVerificationEmailFn, {
    onSuccess: () => {
      setErrorMessage(null);
      setSuccessMessage("Confirm mail sent again");
    },
    onError: (error) => {
      setErrorMessage(error.response?.data?.msg || "Confirm resend failed.");
      setSuccessMessage(null);
    },
  });

  const onSubmit = (data) => {
    //("TEST: " + data);
    mutation.mutate(data);
  };

  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  resetCookies(cookies);

  return (
    <div className="login">
      <div className="login-form-col">
        <div className="login-form-content">
          <h2>{t("unconfirmed")}</h2>
          <p>{t("email-signup-text01")}</p>
          <p>{t("email-signup-text02")}:</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
              <label htmlFor="email">Email:</label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="username user-name e-mail email"
                {...register("email", {
                  required: true,
                  pattern: emailPattern,
                })}
              />
              {errors.email && (
                <InlineToast
                  message={`Email is required`}
                  type="error"
                  size="small"
                />
              )}
              {errors.email && errors.email.type === "pattern" && (
                <InlineToast
                  message={`Please enter a valid email address`}
                  type="error"
                  size="small"
                />
              )}
            </fieldset>
            <button type="submit">{t("resend")}</button>
          </form>
          {errorMessage && <InlineToast message={errorMessage} type="error" />}
          {successMessage && (
            <InlineToast message={successMessage} type="success" />
          )}
        </div>
      </div>
      <div className="login-info"></div>
    </div>
  );
};

export default ResendConfirm;
