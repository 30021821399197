import React from "react";
import { useQuery } from "@tanstack/react-query";
import graphQLClient from "../api/graphqlClient";
import { TASK_QUERY } from "../queries/taskQuery";

import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { renderOptions } from "../utils/richText";

function Task({ id }) {
  const { data, error, isLoading } = useQuery(["task", id], async () => {
    const data = await graphQLClient.request(TASK_QUERY, { id });
    return data;
  });

  if (isLoading) {
    return <div>Loading task...</div>;
  }

  if (error) {
    return <div>Error fetching task: {error.message}</div>;
  }

  return (
    <div className="task">
      <h2>{data.task.title}</h2>
      {data.task.body &&
        documentToReactComponents(
          data.task.body.json,
          renderOptions(data.task.body.links)
        )}
    </div>
  );
}

export default Task;
