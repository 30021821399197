import React from "react";

import { Link } from "react-router-dom";
import BBLearningLogo from "../../images/svg/bechbox_learning_logo.svg";
import InstagramLogo from "../../images/icons/regular/instagram-logo.svg";
import portrait from "../../images/mikkel_portrait.jpg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="wrapper">
        <div className="wrapper-inner">
          <div className="footer-cols">
            <Link to="/" className="header-logo">
              <img src={BBLearningLogo} alt="BechBox Learning logo" />
            </Link>
            <div className="footer-bio">
              <div className="footer-bio-image">
                <img src={portrait} alt="Mikkel Bech" />
              </div>
              <div className="footer-bio-description">
                Jeg hedder Mikkel Bech og har en kandidatgrad i datalogi og er
                desuden fotograf og 3D-artist.
                <p>Se mit foto-portfolio her:</p>
                <div className="button-row">
                  <a
                    href="https://www.bechbox.com/gallery/portrait-portfolio/"
                    target="_blank"
                    rel="noreferrer"
                    className="button-standard"
                  >
                    <button>BechBox Foto</button>
                  </a>
                  <a
                    href="https://www.instagram.com/bechbox/"
                    target="_blank"
                    rel="noreferrer"
                    className="insta-button"
                  >
                    <img src={InstagramLogo} alt="Instagram BechBox" />
                  </a>
                </div>
                <p>og mit 3D arbejde her:</p>
                <div className="button-row">
                  <a
                    href="https://www.bechbox.com/gallery/3d-portfolio/"
                    target="_blank"
                    rel="noreferrer"
                    className="button-standard"
                  >
                    <button>BechBox 3D</button>
                  </a>
                  <a
                    href="https://www.instagram.com/bechbox_3d/"
                    target="_blank"
                    rel="noreferrer"
                    className="insta-button"
                  >
                    <img src={InstagramLogo} alt="Instagram BechBox 3D" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
