export const TASK_QUERY = `
  query TaskQuery($id: String!) {
    task(id: $id) {
      sys {
        id
      }
      title
      body {
        json
        links {
          assets {
            block {
              sys {
                id
              }
              url
              title
              width
              height
              description
            }
          }
        }
      }
    }
  }
`;
