import React from "react";
import ErrorIcon from "../../images/icons/regular/warning-octagon.svg";
import SuccessIcon from "../../images/icons/regular/info.svg";

export default function InlineToast({
  message,
  type,
  size = "medium",
  ...props
}) {
  return (
    <div className={`${type}-div-${size} inline-toast-${size}`} {...props}>
      {type === "error" && (
        <img
          src={ErrorIcon}
          className={`${type}-icon toast-icon-${size}`}
          alt="error icon"
        />
      )}
      {type === "success" && (
        <img
          src={SuccessIcon}
          className={`${type}-icon toast-icon-${size}`}
          alt="error icon"
        />
      )}
      <p className={`${type}-message toast-message`}>{message}</p>
    </div>
  );
}
