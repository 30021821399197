export const COURSE_DETAIL_QUERY = `
  query CourseQuery($id: String!) {
    course(id: $id) {
        title
        seo
        description {
          json
        }
        thumbnail {
          sys {
            id
          }
          url
        }
    }
  }
`;
