export const COURSE_QUERY = `
  query CourseQuery($id: String!) {
    course(id: $id) {
      title
      lessonsCollection {
        items {
          title
          sys {
            id
          }
        }
      }
    }
  }
`;
