import React from "react";
import ReactDOM from "react-dom";
import closeIcon from "../../images/icons/regular/x.svg";

const Modal = ({ onClose, closeButton = "true", size = "md", children }) => {
  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className="modal-backdrop" onClick={handleBackdropClick}>
      <div className={`modal-window modal-window-${size}`}>
        <div className="modal-content">{children}</div>
        {closeButton === "true" && (
          <button className="modal-close" onClick={onClose}>
            <img src={closeIcon} className="modal-close-icon" alt="close" />
          </button>
        )}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};

export default Modal;
