import React from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import InlineToast from "../ui/InlineToast";
import { Link } from "react-router-dom";

import { fetchCoursesFn } from "../../api/courses";
import { createPromoCodesFn } from "../../api/promocodes";
import Spinner from "../ui/Spinner";
import Header from "../ui/Header";

const PromoCodeGenerator = () => {
  const { register, handleSubmit, reset, formState } = useForm();
  const { errors } = formState; // Destructure errors from formState

  const mutation = useMutation((courseData) => createPromoCodesFn(courseData));

  const onSubmit = (data) => {
    mutation.mutate(data, {
      onSuccess: () => {
        reset();
      },
    });
  };

  const {
    isLoading,
    isError,
    error,
    data: allCourses,
  } = useQuery({
    queryKey: ["allCourses"],
    queryFn: () => fetchCoursesFn(),
    staleTime: Infinity,
  });

  if (isLoading) {
    return (
      <div className="load-screen">
        <div className="load-content">
          <Spinner />
          <div className="load-msg">Loading ...</div>
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching courses: {error.message}</div>;
  }

  return (
    <>
      <div className="wrapper">
        <div className="index">
          <Header />
          <div className="wrapper-inner cols">
            <div>
              <h1>Add promo codes</h1>
              <form className="form-default" onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                  <label htmlFor="courseId">Course</label>
                  <select {...register("courseId", { required: true })}>
                    {allCourses.map((course) => (
                      <option key={course.id} value={course.id}>
                        {course.name}
                      </option>
                    ))}
                  </select>
                </fieldset>

                {errors.courseId && (
                  <InlineToast
                    message={`Course is required`}
                    type="error"
                    size="small"
                  />
                )}

                <label>
                  Number of Codes:
                  <input
                    type="number"
                    {...register("numCodes", { required: true, min: 1 })}
                  />
                </label>

                {errors.numCodes && (
                  <InlineToast
                    message={`numCodes is required`}
                    type="error"
                    size="small"
                  />
                )}

                <label>
                  Code Prefix:
                  <input type="text" {...register("prefix")} />
                </label>
                {errors.prefix && (
                  <InlineToast
                    message={`prefix is required`}
                    type="error"
                    size="small"
                  />
                )}

                <button type="submit" disabled={formState.isSubmitting}>
                  Generate Codes
                </button>
              </form>
              {mutation.isError && (
                <p className="error-message">
                  Error creating course: {mutation.error.message}
                </p>
              )}
              {mutation.isSuccess && (
                <p className="success-message">
                  Course added:{" "}
                  <Link to={`/course/${mutation.data.contentful_Id}`}>
                    {mutation.data.name}
                  </Link>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoCodeGenerator;
