import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const registerUserFn = async (userData) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_HOST}/users/register`,
    userData
  );
  return response.data;
};

export const loginUserFn = async (userData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/users/login`,
      userData
    );
    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);

    // You can throw the error again after logging it if you want
    // to handle it elsewhere in your application
    throw error;
  }
};

export const getClientsFn = async (userData) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_HOST}/clients/all`,
    userData
  );
  return response.data;
};

export const resendVerificationEmailFn = async (email) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_HOST}/users/resend-confirmation`,
    email
  );
  return response.data;
};

export const confirmEmailFn = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_HOST}/users/email/confirm/${id}`
  );
  return response.data;
};

export const sendPasswordResetFn = async (email) => {
  const response = await axios.post(
    `${process.env.REACT_APP_SERVER_HOST}/users/reset-password`,
    email
  );
  return response.data;
};

export const saveNewPasswordFn = async ({ password, id, resetToken }) => {
  const configuration = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/users/new-password`,
    data: {
      id: id,
      password: password,
      resetToken: resetToken,
    },
  };
  const response = await axios(configuration);
  return response.data;
};

export const changeProfilePhotoFn = async (userId, photo) => {
  const token = cookies.get("TOKEN");
  const formData = new FormData();
  formData.append("photo", photo);

  const response = await axios.patch(
    `${process.env.REACT_APP_SERVER_HOST}/users/${userId}/upload-photo`,
    formData,
    {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
};

export const fetchUserDataFn = async (userId) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "get",
    url: `${process.env.REACT_APP_SERVER_HOST}/users/get/${userId}`,
    headers: {
      Authorization: token,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

export const fetchUsersFn = async (companyId) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "get",
    url: `${process.env.REACT_APP_SERVER_HOST}/users/all`,
    headers: {
      Authorization: token,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

export const updateUserFn = async (userData) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/users/update/${userData.id}`,
    headers: {
      Authorization: token,
    },
    data: {
      name: userData.name,
      surname: userData.surname,
      email: userData.email,
    },
  };
  const response = await axios(configuration);
  return response.data;
};

export const changePasswordFn = async (userData) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/users/changepassword`,
    headers: {
      Authorization: token,
    },
    data: {
      id: userData.id,
      oldPassword: userData.oldPassword,
      newPassword: userData.newPassword,
    },
  };
  const response = await axios(configuration);
  return response.data;
};

export const toggleEnrollmentFn = async ({ userId, courseId }) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/enrollments/toggle`,
    headers: {
      Authorization: token,
    },
    data: {
      userId: userId,
      courseId: courseId,
    },
  };
  try {
    const response = await axios(configuration);
    return response.data;
  } catch (error) {
    console.error("Error toggling enrollment:", error);
    throw error;
  }
};
