import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default function ProtectedAdminRoutes({ children }) {
  const token = cookies.get("TOKEN");
  const admin = cookies.get("ADMIN");

  return token && admin ? children : <Navigate to="/login" />;
}
