import axios from "axios";

import Cookies from "universal-cookie";

const cookies = new Cookies();

export const fetchPromocodesFn = async (userId) => {
  const token = cookies.get("TOKEN");
  const configuration = {
    method: "get",
    url: `${process.env.REACT_APP_SERVER_HOST}/promocodes/all`,
    headers: {
      Authorization: token,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

export const createPromoCodesFn = async (data) => {
  const token = cookies.get("TOKEN");

  const configuration = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/promocodes/new`,
    headers: {
      Authorization: token,
    },
    data: {
      courseId: data.courseId,
      numCodes: data.numCodes,
      prefix: data.prefix,
    },
  };

  const response = await axios(configuration);
  return response.data;
};

export const redeemPromoCodeFn = async (data) => {
  const token = cookies.get("TOKEN");

  const configuration = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/promocodes/use`,
    headers: {
      Authorization: token,
    },
    data: {
      courseId: data.courseId,
      userId: data.userId,
      promocode: data.promocode,
    },
  };

  const response = await axios(configuration);
  return response.data;
};
