import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import Vimeo from "../components/ui/Vimeo";

export const renderOptions = (links) => {
  // create an asset map
  const assetMap = new Map();

  // loop through the assets and add them to the map
  if (links && links.assets) {
    for (const asset of links.assets.block) {
      assetMap.set(asset.sys.id, asset);
    }
  }

  return {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = assetMap.get(node.data.target.sys.id);
        //console.log(node);
        if (asset) {
          return <img src={asset.url} alt={asset.title} />;
        }

        // Return some fallback in case the asset cannot be found
        return <span>Asset not found</span>;
      },
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data.uri.includes("player.vimeo.com/video")) {
          let videoTitle = "BechBox Learning";
          //console.log("VIDEO");
          //console.log(node);
          if (node.content[0].value !== null) {
            videoTitle = node.content[0].value;
          }
          return <Vimeo videoTitle={videoTitle} videoSrcURL={node.data.uri} />;
        } else if (node.data.uri.includes("youtube.com/embed")) {
          return "YOUTUBE";
        }
        return <a href={node.data.uri}>{children}</a>;
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 style={{ color: "blue" }}>{children}</h2>;
      },
    },
    renderText: (text) => {
      return text.replace("some pattern", "some replacement"); // You can also override how text content is rendered
    },
  };
};
