import React from "react";
import Header from "../components/ui/Header";

const SupportPage = () => {
  return (
    <>
      <div className="wrapper">
        <div className="index">
          <Header />
          <div className="wrapper-inner cols">
            <div>
              <h1>Support</h1>
              <p className="text-md">
                Jeg har lige lanceret dette elærings-system, som jeg selv har
                bygget. Hvis der er problemer af nogen art, så tøv ikke med at
                skrive en mail til{" "}
                <a href="mailto:mikkel@bechbox.dk">mikkel@bechbox.dk</a>
              </p>
            </div>
            <div> </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportPage;
