import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { sendPasswordResetFn } from "../../api/users";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const mutation = useMutation(sendPasswordResetFn, {
    onSuccess: () => {
      setErrorMessage(null);
      setSuccessMessage("Verification email sent successfully!");
    },
    onError: (error) => {
      setSuccessMessage(null);
      setErrorMessage(
        error.response?.data?.message || "Failed to send verification email."
      );
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    mutation.mutate({ email: data.email });
  };

  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  return (
    <div>
      <div className="login">
        <div className="login-info"></div>
        <div className="login-form-col">
          <div className="login-form-content">
            <h2>{t("reset-password")}</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="email">Email:</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  {...register("email", {
                    required: true,
                    pattern: emailPattern,
                  })}
                />
                {errors.email && errors.email.type === "required" && (
                  <p className="form-error">Email is required.</p>
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <p className="form-error">{t("enter-valid-email")}.</p>
                )}
              </div>
              <button type="submit">{t("send-reset-link")}</button>
            </form>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
            <div className="after-form">
              <Link to="/login">{t("go-to-login")}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
