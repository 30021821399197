import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { registerUserFn } from "../../api/users";
import { useParams, useNavigate } from "react-router-dom";
import InlineToast from "../ui/InlineToast";
import LoadingButton from "../ui/LoadingButton";
import { useTranslation } from "react-i18next";

const Register = () => {
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const mutation = useMutation(registerUserFn, {
    onSuccess: () => {
      setErrorMessage(null);
      navigate("/resendconfirm");
    },
    onError: (error) => {
      setErrorMessage(error.response?.data?.msg || "User registration failed.");
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(data);
  };

  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  return (
    <div className="login">
      <div className="login-form-col">
        <div className="login-form-content">
          <h2>{t("register-user")}</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input
              id="companyid"
              name="companyid"
              type="hidden"
              value={id}
              {...register("companyid")}
            />
            <div>
              <fieldset>
                <label htmlFor="name">{t("firstname")}:</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  autoComplete="first-name firstname"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <InlineToast
                    message={`Firstname is required.`}
                    type="error"
                    size="small"
                  />
                )}
              </fieldset>
            </div>
            <div>
              <fieldset>
                <label htmlFor="surname">{t("lastname")}:</label>
                <input
                  id="surname"
                  name="surname"
                  type="text"
                  autoComplete="lastname last-name sur-name"
                  {...register("surname", { required: true })}
                />
                {errors.surname && (
                  <InlineToast
                    message={`Lastname is required.`}
                    type="error"
                    size="small"
                  />
                )}
              </fieldset>
            </div>
            <div>
              <fieldset>
                <label htmlFor="email">Email:</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="username user-name e-mail email"
                  {...register("email", {
                    required: true,
                    pattern: emailPattern,
                  })}
                />
                {errors.email && (
                  <InlineToast
                    message={`Email is required.`}
                    type="error"
                    size="small"
                  />
                )}
                {errors.email && errors.email.type === "pattern" && (
                  <p className="form-error">t{"enter-valid-email"}</p>
                )}
              </fieldset>
            </div>
            <div>
              <fieldset>
                <label htmlFor="password">{t("password")}:</label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="pass-word password"
                  {...register("password", { required: true })}
                />
                {errors.password && (
                  <InlineToast
                    message={`Password is required.`}
                    type="error"
                    size="small"
                  />
                )}
              </fieldset>
            </div>
            {mutation.isLoading ? (
              <LoadingButton type="button" isLoading="true" disabled />
            ) : (
              <button type="submit">{t("register")}</button>
            )}
          </form>
          {errorMessage && (
            <InlineToast message={errorMessage} type="error" size="medium" />
          )}
        </div>
      </div>
      <div className="login-info"></div>
    </div>
  );
};

export default Register;
