import React from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import Cookies from "universal-cookie";
import { enrollUserFn } from "../api/courses";
import { Link } from "react-router-dom";
const cookies = new Cookies();

function PaymentSuccess() {
  const queryClient = useQueryClient();

  function getCheckoutSessionId() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("session_id");
  }

  const fetchCheckoutSession = async () => {
    const sessionId = getCheckoutSessionId();
    if (!sessionId) {
      throw new Error("Session ID not found");
    }

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_HOST}/stripe/checkout-session?session_id=${sessionId}`
    );

    if (!response.ok) {
      throw new Error("Network response was not ok " + response.statusText);
    }

    return response.json();
  };

  const mutation = useMutation((enrollData) => enrollUserFn(enrollData));

  const {
    data: sessionDetails,
    error,
    isFetching,
  } = useQuery(["sessionDetails"], fetchCheckoutSession, {
    retry: false,
    onSuccess: (data) => {
      //console.log(data);
      // Trigger the enrollment mutation with the course ID from the session details
      mutation.mutate({
        userId: cookies.get("ID"),
        courseId: data.metadata.courseId,
      });
    },
  });

  if (isFetching) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="payment-feedback">
      <h1>Thank you for your purchase!</h1>
      <p>Your transaction was successful.</p>
      <h2>Transaction details:</h2>
      <p>Transaction ID: {sessionDetails.payment_intent}</p>
      <p>Status: {sessionDetails.payment_status}</p>
      The course has been added to your course list - go there now by clicking
      this button:
      <br />
      <br />
      <Link to="/dashboard">
        <button className="button-standard">Courses</button>
      </Link>
    </div>
  );
}

export default PaymentSuccess;
