import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import graphQLClient from "../api/graphqlClient";
import { COURSE_QUERY } from "../queries/courseQuery";

import Header from "./ui/Header";
import Lesson from "./Lesson";
import { fetchCourseDataFn } from "../api/courses";
import { useTranslation } from "react-i18next";

import CheckIcon from "../images/icons/regular/check.svg";

import Cookies from "universal-cookie";
import SessionHandler from "./SessionHandler";
import Footer from "./ui/Footer";
import Spinner from "./ui/Spinner";
const cookies = new Cookies();

function Course() {
  const { t } = useTranslation();
  const { id } = useParams();
  const userId = cookies.get("ID");
  const [selectedLessonId, setSelectedLessonId] = useState(null);

  const handleNextLessonClick = () => {
    if (selectedLessonIndex < data.course.lessonsCollection.items.length - 1) {
      setSelectedLessonId(
        data.course.lessonsCollection.items[selectedLessonIndex + 1].sys.id
      );
    }
    window.scrollTo(0, 0);
  };

  const {
    data: courseData,
    isError: isBackendIdError,
    isLoading: isBackendLoading,
    error: backendIdError,
    isSuccess: backendIdSuccess,
  } = useQuery({
    queryKey: ["courseData", id],
    queryFn: () => fetchCourseDataFn(id, userId),
  });

  const {
    data,
    error,
    isLoading,
    isError: isContentError,
    isSuccess: contentLoadedSuccess,
  } = useQuery(
    ["courseLessons", id],
    async () => {
      const data = await graphQLClient.request(COURSE_QUERY, {
        id: courseData.contentful_Id,
      });
      return data;
    },
    { enabled: backendIdSuccess, staleTime: 0 }
  );

  useQuery(
    ["selectedLessonId", data],
    () => {
      if (data && data.course.lessonsCollection.items.length > 0) {
        setSelectedLessonId(data.course.lessonsCollection.items[0].sys.id);
        return data.course.lessonsCollection.items[0].sys.id; // Return a value here
      }
      return null; // Ensure to return a value in all cases
    },
    { enabled: contentLoadedSuccess, staleTime: 0 }
  );

  if (isBackendLoading) {
    return <div>Loading backend...</div>;
  }

  if (isBackendIdError) {
    let message = backendIdError.message;

    if (backendIdError.response && backendIdError.response.status === 403) {
      message = "Sorry, you don't have access to this course";
    }

    return <div>Error fetching course data: {message}</div>;
  }

  if (isLoading) {
    return (
      <div className="load-screen">
        <div className="load-content">
          <Spinner />
          <div className="load-msg">Loading content...</div>
        </div>
      </div>
    );
  }

  if (isContentError) {
    return <div>Error fetching content: {error.message}</div>;
  }

  let completedLessons = [];
  if (
    courseData.courseProgress.length > 0 &&
    courseData.courseProgress[0].completedLessons
  ) {
    completedLessons = JSON.parse(
      courseData.courseProgress[0].completedLessons
    );
  }

  // Figure out if there should be a NEXT button:
  const selectedLessonIndex = data.course.lessonsCollection.items.findIndex(
    (lesson) => lesson.sys.id === selectedLessonId
  );

  return (
    <SessionHandler>
      <div className="wrapper">
        <Header />
        <div className="wrapper-inner">
          <div className="course-container">
            <aside className="sidebar">
              <h2>{t("content")}</h2>
              {data.course.lessonsCollection.items.map((lesson) => (
                <div
                  className={`lesson-button ${
                    completedLessons.includes(lesson.sys.id) ? "completed" : ""
                  } ${
                    lesson.sys.id === selectedLessonId ? "lesson-selected" : ""
                  }`} // <-- This line was updated
                  key={lesson.sys.id}
                  onClick={() => setSelectedLessonId(lesson.sys.id)}
                >
                  <div className="lesson-button-check">
                    {completedLessons.includes(lesson.sys.id) && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </div>
                  {lesson.title}
                </div>
              ))}
            </aside>
            <div className="main">
              {selectedLessonId ? (
                <>
                  <Lesson id={selectedLessonId} courseId={id} />
                  <div className="lesson-nav">
                    {selectedLessonIndex <
                      data.course.lessonsCollection.items.length - 1 && (
                      <button
                        onClick={handleNextLessonClick}
                        className="button-standard"
                      >
                        {t("next-lesson")}
                      </button>
                    )}
                  </div>
                </>
              ) : (
                // Here you would fetch and display the details for the selected lesson based on the selectedLessonId
                <div>Select a lesson to see the details</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </SessionHandler>
  );
}

export default Course;
