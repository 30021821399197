import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchUserCoursesFn, fetchCoursesFn } from "../api/courses";

import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import Header from "./ui/Header";
import { useTranslation } from "react-i18next";
import Footer from "./ui/Footer";
import Spinner from "./ui/Spinner";

const cookies = new Cookies();

const Dashboard = () => {
  const userId = cookies.get("ID");
  const { t } = useTranslation();
  const {
    isLoading,
    isError,
    error,
    data: userCourses,
  } = useQuery({
    queryKey: ["userCourses"],
    queryFn: () => fetchUserCoursesFn(userId),
    staleTime: Infinity,
  });

  const {
    isCoursesLoading,
    isCoursesError,
    coursesError,
    data: allCourses,
  } = useQuery({
    queryKey: ["allCourses"],
    queryFn: () => fetchCoursesFn(),
    staleTime: Infinity,
  });

  if (isLoading) {
    return (
      <div className="load-screen">
        <div className="load-content">
          <Spinner />
          <div className="load-msg">Loading courses...</div>
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>Error fetching courses: {error.message}</div>;
  }

  if (isCoursesLoading) {
    return <div>Loading additional courses...</div>;
  }

  if (isCoursesError) {
    return <div>Error fetching courses: {coursesError.message}</div>;
  }

  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="wrapper-inner">
          <h2>{t("your-courses")}</h2>
          <div className="course-cards">
            {userCourses.courses &&
              userCourses.courses.map((course, index) => (
                <Link to={`/course/${course.id}`} key={index}>
                  <div className="course-card">
                    <div className="course-card-image">
                      <img src={course.thumbnailUrl} alt="course thumbnail" />
                    </div>
                    <h3>{course.name}</h3>
                  </div>
                </Link>
              ))}
          </div>

          {/* Only render "other-courses" section if lengths of userCourses and allCourses are not equal */}
          {allCourses &&
            (userCourses.courses
              ? userCourses.courses.length !== allCourses.length
              : true) && (
              <>
                <h2>{t("other-courses")}</h2>
                {allCourses.map((course, index) => {
                  // Check if the course is already in the user's courses
                  if (
                    userCourses.courses &&
                    userCourses.courses.some(
                      (userCourse) => userCourse.id === course.id
                    )
                  ) {
                    return null; // Do not render the course if it's already in the user's courses
                  }

                  return (
                    <div key={course.id}>
                      <Link to={course.url}>
                        <div className="course-card">
                          <div className="course-card-image">
                            <img
                              src={course.thumbnailUrl}
                              alt="course thumbnail"
                            />
                          </div>
                          <h3>{course.name}</h3>
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </>
            )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
