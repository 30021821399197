import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchPromocodesFn } from "../../api/promocodes";
import Header from "../ui/Header";

const PromocodesList = () => {
  const {
    isLoading,
    isError,
    error,
    data: codes = [],
  } = useQuery({
    queryKey: ["codes"],
    queryFn: () => fetchPromocodesFn(),
    staleTime: Infinity,
  });

  if (isLoading) {
    return <div>Loading promo codes...</div>;
  }

  if (isError) {
    return <div>Error fetching promo codes: {error.message}</div>;
  }

  //console.log(codes);

  return (
    <>
      <div className="wrapper">
        <div className="index">
          <Header />
          <div className="wrapper-inner cols">
            <div>
              <h1>Promo codes</h1>

              <div className="flex-table">
                <div className="flex-table-header">
                  <div className="flex-cell">Code</div>
                  <div className="flex-cell-narrow ">Used</div>
                  <div className="flex-cell">User</div>
                  <div className="flex-cell-wide">Course</div>
                </div>
                {codes.promoCodes.map((code, index) => (
                  <div className="flex-table-row" key={index}>
                    <div className="flex-cell">{code.code}</div>
                    <div className="flex-cell-narrow ">
                      {code.used ? "True" : "False"}
                    </div>
                    <div className="flex-cell">
                      {code.user && code.user.email}
                    </div>
                    <div className="flex-cell-wide">{code.course.name}</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PromocodesList;
