import React from "react";
import { Link } from "react-router-dom";

const PaymentError = () => {
  return (
    <>
      <h1>Something wen't wrong or you cancelled the payment</h1>
      <p>Please contact mikkel@behcbox.dk if you need help!</p>

      <p>
        <Link to="/">Go to front page</Link>
      </p>
    </>
  );
};

export default PaymentError;
